<template>
  <v-container>
    <div class="d-flex">
      <div class="display-1 mb-2 mr-auto">End-Point</div>
      <v-tooltip
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            @click="showDialog = true"
            v-bind="attrs"
            v-on="on"
            fab
            dark
            x-small
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <span>Add New End-Point</span>
      </v-tooltip>
      <v-dialog
        v-model="showDialog"
        :fullscreen="isFullscreen"
        persistent
        max-width="65%"
      >
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <v-col>
                <span class="headline">End-Point</span>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  x-small
                  icon
                  @click="isFullscreen = !isFullscreen"
                >
                  <v-icon>mdi-fullscreen</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <end-point-form
              v-model="endpointForm"
              ref="endpoint"
              @valid="valid = $event"
              @create="handleEndpointFormCreate"
              :subscriptionsList.sync="subscriptions"
              :connectionsList.sync="connections"
              :isFullscreen="isFullscreen"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              small
              rounded
              dark
              @click="isCancel = true, dialogType = 'cancel'"
            >
              <v-icon left>
                mdi-close
              </v-icon>
              Close
            </v-btn>
            <v-btn
              :disabled="!valid"
              color="success"
              small
              rounded
              @click="validateForm(), dialogType = 'create'"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
        <confirm-dialog v-model="isCancel" :type="dialogType" title="end-point" @confirm="confirmDialog()" />
      </v-dialog>
    </div>
    <loading-list v-if="!isFetch" />
    <list-item :items="enpointList" title="id" icon="mdi-link" hasDelete link @delete="deleteItem($event)"></list-item>
    <!-- <monaco-editor></monaco-editor> -->
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import api from '@/services/api'
import endpointTemplateForm from '@/template/endpointTemplate.json'
// import pdf from 'vue-pdf'

export default {
  name: 'EndPoint',
  components: {
    // pdf,
    EndPointForm: () => import('@/components/end-point/EndPointForm'),
    ConfirmDialog: () => import('@/components/ConfirmDialog'),
    ListItem: () => import('@/components/ListItem'),
    LoadingList: () => import('@/components/LoadingList')
    // MonacoEditor: () => import('@/components/MonacoEditor')
    // NewEndPoint: () => import('@/components/end-point/NewEndPoint')
    // Test: () => import('@/components/Test')
  },
  data: () => ({
    endpointForm: JSON.parse(JSON.stringify(endpointTemplateForm)),
    enpointList: [],
    subscriptions: [],
    connections: [],
    showDialog: false,
    isFullscreen: false,
    isCancel: false,
    dialogType: 'cancel',
    valid: true,
    isFetch: false,
    al: false,
    snackbar: {
      active: false,
      type: 'info',
      title: 'test',
      text: 'OK'
    }
  }),
  methods: {
    tt: function () {
      this.$toast.success(this.globalToastFn('testes', 'bsdfgsdgsdgsdfg'))
    },
    fetchEndpoints: function () {
      const vm = this
      // const list = [
      //   api.get(`/api/services/${this.$store.state.fiwareService}/endpoints`, this.$store.state.fiwareService, null, this.$store.state.tokenRPT),
      //   api.get(`/api/services/${this.$store.state.fiwareService}/connections`, this.$store.state.fiwareService, null, this.$store.state.tokenRPT)
      // ]
      // list.reduce((seq, n) => {
      //   seq.then((resp) => {
      //     console.log(resp)
      //   })
      // }, Promise.resolve())
      Promise.resolve().then(() => {
        return api.get(`/api/services/${this.$store.state.fiwareService}/endpoints`, null, null, this.$store.state.tokenRPT)
      }).then((response) => {
        // console.log(response.status)
        const msg = this.globalToastFn('Fetch: Connections', response.statusText)
        if (response.status === 200) {
          response.json().then(json => {
            this.ep = json
            const list = json.map((endpoints) => {
              let source = {}
              source.id = endpoints._id
              Object.assign(source, { ...endpoints._source })
              return source
            })
            this.enpointList = list
          })
        } else {
          this.$toast.error(msg)
        }
      }).then(() => {
        return api.get(`/api/services/${this.fiwareService}/connections`, null, null, this.$store.state.tokenRPT).then((response) => {
          const msg = this.globalToastFn('Fetch: Connections', response.statusText)
          if (response.status === 200) {
            response.json().then((json) => {
              vm.connections = json
              for (let connection of json) {
                // vm.fetchMQTTSubscriptions(connection['_source']['name'])
                api.get(`/api/services/${this.fiwareService}/connections/${connection['_source']['name']}/subscriptions`, this.fiwareService, null, this.$store.state.tokenRPT).then((responseSub) => {
                  const msg2 = this.globalToastFn('Fetch: Subscriptions', response.statusText)
                  if (responseSub.status === 200) {
                    responseSub.json().then((jsonSub) => {
                      vm.subscriptions = [...vm.subscriptions, ...jsonSub]
                    })
                  } else {
                    this.$toast.error(msg2)
                  }
                })
              }
            })
          } else {
            this.$toast.error(msg)
          }
        })
      })
      this.isFetch = true
    },
    deleteItem: function (id) {
      let vm = this
      let ids = id.split(':').pop()
      api.delete(`/api/services/${this.$store.state.fiwareService}/endpoints/${ids}`, null, null, this.$store.state.tokenRPT).then((response) => {
        let msg = this.globalToastFn(`Delete: ${ids}`, response.statusText)
        if (response.status === 200) {
          this.$toast.success(msg)
          vm.fetchEndpoints()
        } else {
          this.$toast.error(msg)
        }
      })
    },
    openPdf: function () {
      // window.open(require('./public/rr.pdf'))
    },
    testToast: function () {
      const msg = this.globalToastFn('Create: qweqwe', 'OK')
      this.$toast.error(msg)
    },
    validateForm: function () {
      let epF = this.$refs.endpoint.$refs.form.validate()
      let connF = true
      let subF = true
      if (this.$refs.endpoint.$refs.connectionsFn) {
        connF = this.$refs.endpoint.$refs.connectionsFn.$refs.form.validate()
      }
      if (this.$refs.endpoint.$refs.subscriptionsFn) {
        subF = this.$refs.endpoint.$refs.subscriptionsFn.$refs.form.validate()
      }
      // console.log(epF, connF, subF)
      this.valid = epF && connF && subF
      if (this.valid) this.isCancel = true
    },
    confirmDialog: function () {
      if (this.dialogType === 'create') {
        this.$refs.endpoint.createEndpoint()
      } else if (this.dialogType === 'cancel') {
        this.$refs.endpoint.resetValidateForm()
        this.endpointForm = JSON.parse(JSON.stringify(endpointTemplateForm))
        this.$refs.endpoint.resetForm()
        this.showDialog = false
      }
    },
    handleEndpointFormCreate: function () {
      this.endpointForm = JSON.parse(JSON.stringify(endpointTemplateForm))
      this.$refs.endpoint.resetForm()
      this.fetchEndpoints()
      this.showDialog = false
    },
    fetchMQTTSubscriptions: function (connection) {
      let vm = this
      api.get(`/api/services/${this.fiwareService}/connections/${connection}/subscriptions`, null, null, this.$store.state.tokenRPT).then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            vm.subscriptions = [...vm.subscriptions, ...json]
          })
        }
      })
    }
  },
  computed: {
    ...mapGetters(['fiwareService']),
    breadcrumb: function () {
      const path = this.$route.path.split('/').filter((pf) => pf)
      const items = []
      path.forEach((p, index) => {
        let pathLink = ''
        for (let i = 0; i <= index; i++) {
          pathLink = pathLink.concat(`/${path[i]}`)
        }
        items.push({
          text: p,
          disabled: false,
          to: pathLink
        })
      })
      return items
    }
  },
  created () {
    this.fetchEndpoints()
  },
  mounted () {
    // console.log(this.$store.state.fiwareService)
    // this.fetchEndpoints()
  },
  watch: {
    '$store.state.fiwareService': function () {
      // console.log(this.$store.state.fiwareService)
      this.fetchEndpoints()
    }
  }
}
</script>
